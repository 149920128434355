<template>
  <div>
    <v-card-title>
      {{ $t('menu.configuration') }} / {{ $t('menu.roles') }}
      <span
        v-if="currentTab === 1"
        class="pl-2"
      > / {{ $t('menu.permisos') }}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-if="permisos.includes('role:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            v-if="currentTab === 0"
            color="primary"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :loading="loading"
            @click="savePermisos()"
          >
            <span>{{ $t('btn.update') }}</span>
            <v-icon>{{ icons.mdiUpdate }}</v-icon>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <!--<v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>-->
      </v-row>
    </v-card-text>

    <v-tabs v-model="currentTab">
      <v-tab
        v-if="
          permisos.includes('role:list') ||
            permisos.includes('*')
        "
        @click="setViewTabOther"
      >
        {{ $t('menu.roles') }}
      </v-tab>
      <v-tab
        v-if="
          permisos.includes('role:edit') ||
            permisos.includes('*')
        "
        @click="setViewTab"
      >
        {{ $t('menu.permisos') }}
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="currentTab">
      <v-tab-item>
        <RolesTab v-if="!isLoading" />
      </v-tab-item>
      <v-tab-item>
        <PermisosTab v-if="$store.state.app.viewPermisos === 'rols'" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'
import RolesTab from '../tabs/Roles.vue'
import PermisosTab from '../tabs/Permisos.vue'

export default {
  components: {
    RolesTab,
    PermisosTab,
  },
  data() {
    return {
      currentTab: 0,
      isLoading: true,
      loading: false,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  mounted() {
    this.profile()
  },
  methods: {
    ...mapMutations(['setPermisosTab']),
    setViewTab() {
      this.$store.state.app.isLoadingPagePermisos = true
      this.setPermisosTab('rols')
    },
    setViewTabOther() {
      this.$store.state.app.viewPermisos = 'other'
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    newItem() {
      localStorage.removeItem('role-id')
      this.$router.push({ name: 'role-update' })
    },
    savePermisos() {
      this.loading = true

      const json = {
        perfiles: this.$store.state.app.sourcePermisos,
      }

      // console.log(json)

      this.axios
        .post(`${process.env.VUE_APP_API_URL}role/update-all-permisos`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          // eslint-disable-next-line no-empty
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
